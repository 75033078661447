/*@import url("https://fonts.googleapis.com/css?family=Special+Elite&display=swap");*/

@import url("/fonts/fonts.css");

* {
  box-sizing: border-box;
}
html {
  font-size: calc(1em + 0.5vw);
  padding: 0;
  margin: 0;
}
p {
  word-break: break-word;
  line-height: 1.5;
}

body {
  overscroll-behavior: contain;
  background: #212121;
  color: #cacac0;
  text-align: center;
  font-family: "NoirPro", sans-serif;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
p,
div,
input,
button,
textarea,
select,
option {
  font-family: "NoirPro", sans-serif;
}
label {
  display: inline-block;
}
.full-over {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
}
.full-over div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
a,
a:visited,
a:focus,
a:active {
  color: #c72f40;
  text-decoration: none;
}

input {
  background: none;
  border: none;
  outline: none !important;
  text-align: center;
  color: #fff;
  border-bottom: 2px solid #c72f40;
  padding: 15px 20px;
  display: block;
  font-size: 1.5rem;
  margin: 15px auto;
  font-family: "NoirPro", sans-serif;
  font-weight: 300;
  width: 100%;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #2d282c inset !important;
  box-shadow: 0 0 0 1000px #2d282c inset !important;
  -webkit-text-fill-color: #eee !important;
}

.burger {
  display: inline-block;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #eee;
  margin: 4px 0;
  transition: all 0.4s;
}

.burger_open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger_open .bar2 {
  opacity: 0;
}

.burger_open .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Service Worker Update */
#snackbar {
  min-width: 250px;
  background-color: #c72f40;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}
/* Service Worker */
